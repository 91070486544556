<template>
    <div class="content-max-width">
        <h5 class="mb-2">
            Accord entre l'utilisateur et le mode de vie
        </h5>
        <h6 class="tertiary--text mb-2">
            Dernière mise à jour le 16 mars 2020
        </h6>
        <p>
            Bienvenue! Voici les conditions d'utilisation («Conditions») qui régissent votre utilisation de Live Nation et
            Les sites et applications mobiles de Ticketmaster - y compris, mais sans s'y limiter, www.livenation.com,
            www.ticketmaster.com, www.ticketsnow.com et www.ticketexchangebyticketmaster.com
            - (collectivement, le «Site»), et votre achat, possession ou utilisation de tout billet Live Nation ou Ticketmaster,
            produits ou services.
        </p>
        <p>
            Notre politique de confidentialité, politique d'achat et toutes autres politiques, règles ou directives qui peuvent être
            applicable à des offres ou fonctionnalités particulières sur le
            Le site est également intégré dans ces conditions.
            En visitant ou en utilisant le Site, vous acceptez expressément ces Conditions, telles que mises à jour de temps à autre.
        </p>
        <h6 class="mb-2">
            AVIS CONCERNANT LES MODIFICATIONS FUTURES DES CONDITIONS:
        </h6>
        <p>
            Nous pouvons apporter des modifications à ces conditions à tout moment.
            Toutes les modifications que nous apportons seront effectives immédiatement lorsque nous publierons une version révisée
            version de ces Conditions sur le Site.
            La date de «dernière mise à jour» ci-dessus vous indiquera quand ces conditions ont été révisées pour la dernière fois.
            En continuant à utiliser ce site après cette date, vous acceptez les modifications.
        </p>
        <h6 class="mb-2">
            AVIS CONCERNANT L'ARBITRAGE ET LA RENONCIATION AU RECOURS COLLECTIF:
        </h6>
        <p>
            Ces conditions contiennent une convention d'arbitrage et une renonciation à un recours collectif,
            par lequel vous acceptez que tout litige ou réclamation lié de quelque manière que ce soit à votre utilisation de
            le Site, ou aux produits ou services vendus, distribués, émis ou servis par nous ou
            par notre intermédiaire, sera résolu par un arbitrage individuel contraignant, plutôt que par
            tribunal, et vous renoncez à votre droit de participer à un
            recours collectif ou arbitrage collectif. Nous expliquons cet accord et
            renonciation, avec quelques exceptions limitées, à la section 17 ci-dessous.
        </p>
        <p>
            Veuillez noter que même si certains des événements répertoriés sur le site peuvent faire appel
            aux enfants, le Site n'est pas destiné à
            les enfants de moins de 13 ans et ils ne sont pas autorisés à utiliser le Site.
            Nous encourageons vivement tous les parents et tuteurs à surveiller l'utilisation d'Internet
            par leurs enfants. Si vous utilisez le Site, vous affirmez avoir au moins 13 ans.
        </p>
        <h6 class="mb-2">
            1. Enregistrement du Compte
        </h6>
        <p>
            Vous pouvez naviguer sur le site sans vous inscrire à un compte.
            Vous devrez créer un compte pour utiliser certaines fonctionnalités du
            Site, comme la réservation ou l'achat d'un billet. Le nom d'utilisateur de votre compte peut
            ne pas inclure le nom d'une autre personne avec l'intention d'usurper l'identité
            personne, ou être offensant, vulgaire ou obscène. Votre nom d'utilisateur et
            les mots de passe vous sont personnels. Vous serez responsable de la confidentialité
            et l'utilisation de votre nom d'utilisateur et mot de passe, et pour toutes les activités
            (y compris les achats) qui sont effectués via votre compte.
            Vous ne pouvez pas transférer ni vendre l'accès à votre compte. Nous ne serons pas responsables de tout
            préjudice lié à la divulgation de votre nom d'utilisateur ou mot de passe ou à l'utilisation par quiconque
            autre de votre nom d'utilisateur ou mot de passe. Vous ne pouvez pas utiliser le compte d'un autre utilisateur
            sans la permission de cet utilisateur. Vous nous aviserez immédiatement par écrit si vous
            découvrir toute utilisation non autorisée de votre compte ou autre sécurité liée au compte
            violation. Nous pouvons vous demander de changer votre nom d'utilisateur et / ou votre mot de passe si nous
            pensez que votre compte n'est plus sécurisé ou si nous recevons une plainte selon laquelle votre
            nom d'utilisateur viole les droits de quelqu'un d'autre.
            Vous n'aurez aucun droit de propriété sur votre compte ou votre nom d'utilisateur.
            Nous pouvons refuser l'enregistrement, annuler un compte ou refuser l'accès
            sur le site pour quelque raison que ce soit.
        </p>
        <h6 class="mb-2">
            2. Code de conduite
        </h6>
        <p>
            Vous acceptez de vous conformer à toutes les lois, règles et réglementations applicables,
            et que vous n'allez pas:
        </p>
        <p class="pl-2">
            Vous acceptez de vous conformer à toutes les lois, règles et réglementations applicables et que vous ne:
        </p>
        <p class="pl-2">
            Utiliser le site à des fins illégales;
        </p>
        <p class="pl-2">
            Exprimer ou laisser entendre que toutes les déclarations que vous faites sont approuvées par nous, sans notre consentement écrit préalable;
        </p>
        <p class="pl-2">
            Usurper l'identité de toute personne ou entité, réelle ou fictive, y compris tout employé ou représentant de notre société;
        </p>
        <p class="pl-2">
            Soumettez (a) tout contenu ou information qui est illégal, frauduleux, diffamatoire,
            diffamatoire, ou autrement répréhensible, ou enfreint notre ou tout tiers
            propriété intellectuelle ou autres droits; (b) toute information non publique sur
            entreprises sans autorisation; ou (c) toute publicité,
            sollicitations, chaînes de lettres, systèmes pyramidaux, sondages, concours, investissements
            opportunités ou autres communications commerciales non sollicitées;
        </p>
    </div>
</template>
<script>
    export default {
        name: 'TermsAgreement'
    };
</script>
